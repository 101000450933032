import { createContext, useState } from 'react';

const UserContext = createContext({
    userData:{ user: null }
});

const UserProvider = ({ children }) => {

    // datos del usuario logueado
    const [userData, setUserData] = useState(null);

    const isLoggedIn = () => {
        return (userData != null && userData.user != null);
    }
    
    const esAdministrador = () => {        
        return (userData != null && userData.user.tipoUsuario === 1);
    }

    const esJugador = () => {        
        return (userData != null && userData.user.tipoUsuario === 0);
    }

    return (
        <UserContext.Provider value={{ userData, setUserData, isLoggedIn, esAdministrador, esJugador }}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };